import { cx, VariantProps } from "cva";
import bodyStyles from "~/ui-library/shared-styles/text-body";

export type BodyProps<T extends React.ElementType = "div"> = {
  as?: T;
  children: React.ReactNode;
} & VariantProps<typeof bodyStyles> &
  React.ComponentPropsWithoutRef<T>;

export function Body<T extends React.ElementType = "div">({
  as,
  className,
  mdSize,
  size,
  bold,
  ...rest
}: BodyProps<T>): JSX.Element {
  const Component = as || "div";
  return (
    <Component
      className={cx(bodyStyles({ bold, mdSize, size }), className)}
      {...rest}
    />
  );
}
