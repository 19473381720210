import { AxiosRequestConfig } from "axios";
import { endilApiClient } from "~/lib/client/endilApiClient";
import { EditPostResponse } from "~/pages/api/edit/[postParam].page";
import { SubscriptionResponse } from "~/pages/api/piano/user-subscriptions-helpers";
import { SearchyApiRequest } from "~/pages/api/search.page";
import { ConfigureSubscriptionsRequest } from "~/pages/api/v1/email/subscriptions/configure.page";
import { UpsertListsRequest } from "~/pages/api/v1/email/subscriptions/lists/upsert.page";
import {
  ProcessLoginRequest,
  ProcessLoginResponse,
} from "~/pages/api/v1/process-login.page";
import { RequestDemoEndpointPayload } from "~/pages/api/v1/request-demo.page";
import { UpsertUserInfoRequest } from "~/pages/api/v1/onboarding/upsert.page";
import { RegisterUserRequest } from "~/pages/api/v1/user.page";
import { type SignedUrl } from "~/types/asgard/SignedUrl";
import { SearchyResponse } from "~/types/searchy/SearchyResponse";
import { SidecarBlock } from "~/components/Sidecar/sidecar-schemas";

export const fetchSearchyResults = (
  params: SearchyApiRequest["query"],
  config?: AxiosRequestConfig
) =>
  endilApiClient.get<SearchyResponse>(`/api/search`, {
    ...config,
    params,
  });

export const fetchUserSubscriptions = (config?: AxiosRequestConfig) =>
  endilApiClient.get<SubscriptionResponse>("/api/piano/user-subscriptions", {
    ...config,
  });

export const createUserAccount = (
  payload: RegisterUserRequest["body"],
  config?: AxiosRequestConfig
) =>
  endilApiClient.post<unknown>("/api/v1/user", payload, {
    ...config,
  });

export const fetchReportSignedUrl = (
  slug: string,
  config?: AxiosRequestConfig
) =>
  endilApiClient.get<SignedUrl>(`/api/v1/reports/${slug}/download`, {
    ...config,
  });

export const fetchSignedDownloadUrl = (
  attachmentId: string | number,
  config?: AxiosRequestConfig
) =>
  endilApiClient.get<SignedUrl>(`/api/v1/download/${attachmentId}`, {
    ...config,
  });

export const fetchSignedAssetDownloadUrl = (
  postId: string | number,
  attachmentId: string | number,
  config?: AxiosRequestConfig
) =>
  endilApiClient.get<SignedUrl>(
    `/api/v1/asset-download/${postId}/${attachmentId}`,
    {
      ...config,
    }
  );

export const upsertEmailLists = (
  payload: UpsertListsRequest["body"],
  config?: AxiosRequestConfig
) =>
  endilApiClient.post<unknown>(
    "/api/v1/email/subscriptions/lists/upsert",
    payload,
    {
      ...config,
    }
  );

export const configureSubscriptions = (
  payload: ConfigureSubscriptionsRequest["body"],
  config?: AxiosRequestConfig
) =>
  endilApiClient.post<unknown>(
    "/api/v1/email/subscriptions/configure",
    payload,
    {
      ...config,
    }
  );

export const upsertOnboardingUserInfo = (
  payload: UpsertUserInfoRequest["body"],
  config?: AxiosRequestConfig
) =>
  endilApiClient.post<unknown>("/api/v1/onboarding/upsert", payload, {
    ...config,
  });

export const syncPermissions = (config?: AxiosRequestConfig) =>
  endilApiClient.post<unknown>("/api/v1/permissions", null, {
    ...config,
  });

export const processLogin = (
  payload: ProcessLoginRequest["body"],
  config?: AxiosRequestConfig
) =>
  endilApiClient.post<ProcessLoginResponse>("/api/v1/process-login", payload, {
    ...config,
  });

export const requestDemo = (
  payload: RequestDemoEndpointPayload,
  config?: AxiosRequestConfig
) =>
  endilApiClient.post<unknown>("/api/v1/request-demo", payload, {
    ...config,
  });

export const editPost = (postParam: string, config?: AxiosRequestConfig) =>
  endilApiClient.post<EditPostResponse>(`/api/edit/${postParam}`, {
    ...config,
  });

type FetchSidecarPayload = {
  postId: string | number;
};

export const fetchSidecar = (
  { postId }: FetchSidecarPayload,
  config?: AxiosRequestConfig
) =>
  endilApiClient.get<SidecarBlock[]>(`/api/v1/sidecar/${postId}`, {
    ...config,
  });
